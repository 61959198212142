import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player/file";
import "./overlay.css"; // Import the CSS file for overlay styles

const App = () => {
  const [noCount, setNoCount] = useState(0);
  const [yesPressed, setYesPressed] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false); // Track video visibility
  const [headerVisible, setHeaderVisible] = useState(false); // Track video visibility
  const [buttonsVisible, setButtonsVisible] = useState(false); // Track video visibility

  const [videoYesVisible, setVideoYesVisible] = useState(false); // Track video visibility
  const [headerYesVisible, setHeaderYesVisible] = useState(false); // Track video visibility

  const [yesButtonClicked, setYesButtonClicked] = useState(false); // Track if "Yes" button is clicked

  const videoStartRef = useRef(null);
  const videoYesRef = useRef(null);
  const yesButtonSize = noCount * 20 + 16;

  const handleNoClick = () => {
    setNoCount((prevCount) => prevCount + 1);
  };

  const getNoButtonText = () => {
    const phrases = [
      "Ні",
      "Ти впевнена?",
      "Точно впевнена?",
      "А подумать!?",
      "Останній шанс!",
      "Точно ні?",
      "Пожалкуєш же!",
      "Добре, ще один шанс!",
      "Ти точно абсолютно впевнена?",
      "Це точно помилка!",
      "У тебе є серце!?",
      "Ти холодна як холодець!",
      "Може передумала?",
      "А може таки да?",
      "А подарунок хочеш?",
      "Стлесс! 😭",
    ];

    return phrases[Math.min(noCount, phrases.length - 1)];
  };

  const handleYesClick = () => {
    setYesPressed(true);
    setYesButtonClicked(true); // Set "Yes" button clicked
  };

  useEffect(() => {
    // Set videoVisible to true after a delay to allow for the transition
    const delay = setTimeout(() => {
      setVideoVisible(true);
    }, 500); // Adjust the delay time as needed

    const delayHeader = setTimeout(() => {
      setHeaderVisible(true);
    }, 900);

    const delayButtons = setTimeout(() => {
      setButtonsVisible(true);
    }, 1200);

    // If "Yes" button is clicked, set visibility of content inside YesVideoBlock
    if (yesButtonClicked) {
      const delayVideoYes = setTimeout(() => {
        setVideoYesVisible(true);
      }, 500); // Adjust the delay time as needed

      const delayHeaderYes = setTimeout(() => {
        setHeaderYesVisible(true);
      }, 900);

      // Clear the timeouts to prevent memory leaks
      return () => {
        clearTimeout(delayVideoYes);
        clearTimeout(delayHeaderYes);
      };
    }

    // Clear the timeouts to prevent memory leaks
    return () => {
      clearTimeout(delay);
      clearTimeout(delayHeader);
      clearTimeout(delayButtons);
    };
  }, [yesButtonClicked]);

  return (
    <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-6 sm:py-12 ">
      <img src="/img/valentines-day-background-2023-11-27-05-28-50-utc.jpg" alt="" className="absolute left-1/2 top-1/2 md:max-w-none -translate-x-1/2 -translate-y-1/2 sx:max-w-7xl"  />
      <div className="relative bg-white pb-8 sx:pt-4 md:pt-3 shadow-xl ring-1 ring-gray-900/5 sx:mx-auto sx:max-w-sm sx:rounded-lg sx:my-30 sm:mx-auto  sm:rounded-lg">
        <div className="flex flex-col items-center justify-center mx-auto max-w-md">
      {yesPressed ? (
        <div id="YesVideoBlock">
          <div className={`video-start ${videoYesVisible ? 'visible' : ''} `}>
            <ReactPlayer
              ref={videoYesRef}
              url="/video_yes.mp4"
              loop={true}
              playing={true}
              pip={false}
              playsinline={true}
            />
          </div>
          <h1 className={`lg:text-4xl sm:text-3xl sx:text-3xl mt-5 mb-1 text-center text-content ${headerYesVisible ? 'visible' : ''} amatic-sc-bold text-balance`}>
            Добре!<br />Заїжджайте за подарунком 😘
          </h1>
        </div>
      ) : (
        <>
          {/* Render videoStartRef with the appropriate class based on videoVisible state */}
          <div className={`video-start ${videoVisible ? 'visible' : ''} `}>
            <ReactPlayer
              ref={videoStartRef}
              url="/video_start.mp4"
              loop={true}
              playing={true}
              pip={false}
              playsinline={true}
              muted={true}
            />
          </div>
          <h1 className={`lg:text-4xl sm:text-3xl sx:text-3xl my-4 text-center text-content ${headerVisible ? 'visible' : ''} amatic-sc-bold`}>
            Ти будеш моєю <b className="underline decoration-pink-500/30">Валер</b>нтинкою!?
          </h1>
          <div className={`flex flex-col items-center justify-center button-group ${buttonsVisible ? 'visible' : ''} mb-2`}>
            <button
              className={`bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded `}
              style={{ fontSize: yesButtonSize }}
              onClick={handleYesClick}
            >
              Так
            </button>
            <button
              onClick={handleNoClick}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-2 "
            >
              {noCount === 0 ? "Ні" : getNoButtonText()}
            </button>
          </div>
        </>
      )}
      </div>
      </div>
    </div>

  );
};

export default App;
